import React from "react";
// SSS Sangli
// import HeaderImg from "./header-img.png";

// ACS PALUS
//  import HeaderImg from "./header-img-palus.png";

// LES 
// import HeaderImg from "./header-img-les.png";

// Demo project
// import HeaderImg from './demoProjectLogo.png'

// anglo
// import angloImg from './Muslim-Edu-Logo.png'

// turchi
// import turchiImg from './Turchi-Logo.png'

// bhilwadi
// import bhilwadiImg from './Bhilawadi-Logo.png'

// mtes
// import mtesImg from './mtes-logo.png'

// Pandit Vishnu Paluskar
// import pvdpssp from './pvdpssp-logo.png'

// narnde
import narnde from './narnde-logo.png'

// jamsande
// import jamsande from './jamsande-logo.png'

// shirgaon
// import shirgaon from './shirgaon-logo.png'

// devgad
// import devgad from './devgad-logo.png'

// shirdhon
// import shirdhon from './shirdhon-logo.png'

// padel
// import padel from './padel-logo.png'

// sankh
// import sankh from './sankh-logo.png'
// import sankh1 from './sankh-logo1.png'

// mgvt talebajar
// import talebajar from './talebajar-logo.png'

// sankh
// import snehal from './snehal-logo.png'
// import snehal1 from './snehal-logo1.png'

// jay shivray
// import jayShivray from './jay-shivray-logo.png'


import { Height } from "@mui/icons-material";

const Header = () => {
    return (
        <>
            <div className="container" style={{ padding: "8px 0 8px 0", display: "flex" }}>
                {/* <img src={HeaderImg} alt="Branding Image" title="Brand" className="img-fluid HeaderImg" /> */}

                {/* <img src={DemoHeaderImage} alt="Branding Image" title="Brand" className="img-fluid HeaderImg"/> */}

                {/* <img src={angloImg} alt="Branding Image" title="Brand" className="img-fluid HeaderImg" style={{ Height: "120px", width: "120px" }} /> */}
                {/* <h2 style={{marginTop:"30px"}}>मुस्लिम एज्युकेशन कमिटी, सांगली</h2> */}

                {/* <img src={turchiImg} alt="Branding Image" title="Brand" className="img-fluid HeaderImg" style={{ Height: "120px", width: "120px" }} /> */}
                {/* <h2 style={{marginTop:"30px"}}>श्री. सिद्धेश्वर शिक्षण प्रसारक मंडळ, तुरची</h2> */}

                {/* <img src={bhilwadiImg} alt="Branding Image" title="Brand" className="img-fluid HeaderImg" style={{ Height: "80px", width: "80px" }} /> */}
                {/* <h2 style={{ marginTop: "25px", marginLeft: "5px" }}> भिलवडी शिक्षण संस्था</h2> */}

                {/* <img src={mtesImg} alt="Branding Image" title="Brand" className="img-fluid HeaderImg" style={{ Height: "100px", width: "100px" }} /> */}
                {/* <h2 style={{ marginTop: "30px", marginLeft: "5px" }}>एमटीईएस मराठी शाळा, सांगली</h2> */}

                {/* <img src={pvdpssp} alt="Branding Image" title="Brand" className="img-fluid HeaderImg" style={{ Height: "110px", width: "110px" }} /> */}
                {/* <h2 style={{ marginTop: "35px", marginLeft: "5px" }}>पंडित विष्णू दिगंबर पलूसकर बहु. शिक्षण संस्था, पलूस</h2> */}

                <img src={narnde} alt="Branding Image" title="Brand" className="img-fluid HeaderImg" style={{ Height: "100px", width: "100px" }} />
                <h2 style={{ marginTop: "30px", marginLeft: "5px" }}>नागनाथ एज्युकेशन सोसायटी, नरंदे</h2>
                
                {/* <img src={jamsande} alt="Branding Image" title="Brand" className="img-fluid HeaderImg" style={{ Height: "100px", width: "100px" }} /> */}
                {/* <h2 style={{ marginTop: "30px", marginLeft: "5px" }}>विद्या विकास मंडळ, जामसंडे</h2> */}
                
                {/* <img src={shirgaon} alt="Branding Image" title="Brand" className="img-fluid HeaderImg" style={{ Height: "100px", width: "100px" }} /> */}
                {/* <h2 style={{ marginTop: "30px", marginLeft: "5px" }}>शिरगांव पंचक्रोशी (ता. देवगड) शिक्षण प्रसारक मंडळ, मुंबई</h2> */}
                
                {/* <img src={devgad} alt="Branding Image" title="Brand" className="img-fluid HeaderImg" style={{ Height: "100px", width: "100px" }} /> */}
                {/* <h2 style={{ marginTop: "30px", marginLeft: "5px" }}>देवगड एज्युकेशन बोर्ड, मुंबई</h2> */}
                
                {/* <img src={shirdhon} alt="Branding Image" title="Brand" className="img-fluid HeaderImg" style={{ Height: "100px", width: "100px" }} /> */}
                {/* <h2 style={{ marginTop: "30px", marginLeft: "5px" }}>ज्ञानदीप शिक्षण प्रसारक मंडळ, शिरढोण</h2> */}
                
                {/* <img src={padel} alt="Branding Image" title="Brand" className="img-fluid HeaderImg" style={{ Height: "100px", width: "100px" }} /> */}
                {/* <h2 style={{ marginTop: "30px", marginLeft: "5px" }}>पडेल ग्राम सुधारणा मंडळ, मुंबई</h2> */}

                {/* <img src={sankh} alt="Branding Image" title="Brand" className="img-fluid HeaderImg" style={{ Height: "80px", width: "80px" }} /> */}
                {/* <h2 style={{ marginTop: "30px", marginLeft: "5px" }}>श्री शिवलिंगेश्वर शिक्षण संस्था, संख</h2> */}
                {/* <img src={sankh1} alt="Branding Image" title="Brand" className="img-fluid HeaderImg" style={{ Height: "80px", width: "80px", marginLeft: "10px" }} /> */}
                
                {/* <img src={talebajar} alt="Branding Image" title="Brand" className="img-fluid HeaderImg" style={{ Height: "100px", width: "100px" }} /> */}
                {/* <h2 style={{ marginTop: "30px", marginLeft: "5px" }}>तळेबाजार पंचक्रोशी शिक्षण प्रसारक मंडळ, तळेबाजार</h2> */}

                {/* <img src={snehal} alt="Branding Image" title="Brand" className="img-fluid HeaderImg" style={{ Height: "80px", width: "80px" }} /> */}
                {/* <h2 style={{ marginTop: "30px", marginLeft: "5px" }}>स्नेहल फाउंडेशन रुई</h2> */}
                {/* <img src={snehal1} alt="Branding Image" title="Brand" className="img-fluid HeaderImg" style={{ Height: "80px", width: "80px", marginLeft: "10px" }} /> */}
                                
                {/* <img src={jayShivray} alt="Branding Image" title="Brand" className="img-fluid HeaderImg" style={{ Height: "120px", width: "120px" }} /> */}
                {/* <h2 style={{ marginTop: "30px", marginLeft: "5px" }}>जय शिवराय एज्युकेशन सोसायटीचे</h2> */}
                
            </div>
        </>
    )
}
export default Header;